<template>
  <div>
    <GmapMap :center="location" :zoom="12" style="width: 100%; height: 400px">
      <GmapMarker :position="location" />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "Map",
  props: ["location"],
};
</script>

<style scoped>
</style>