<template>
  <div class="bg-gray-main mb-12">
    <div v-if="companyDataLoaded" class="container py-20">
      <div class="lg:w-10/12 mx-auto">
        <div class="lg:flex">
          <div
            class="
              border
              border-gray-border
              border-right-0
              border-l-0
              border-b-1
              border-t-0
              border-r-0
              lg:border-r lg:border-b-0 lg:w-2/12
              mb-12
              pb-6
              lg:mb-0 lg:pb-0
              text-center
              lg:text-left
            "
          >
            <div>
              <div v-if="company.logoPaths">
                <img
                  class="mx-auto w-32 lg:mx-0"
                  :src="company.logoPaths"
                  alt="Logo"
                />
              </div>
              <div v-else>
                <router-link
                  class="text-primary-purple"
                  :to="{
                    name: 'AddCompanyEntry',
                    params: { id: company.id },
                  }"
                >
                  <div class="cursor-pointer">
                    <img
                      class="pl-6 lg:mx-0"
                      src="@/assets/upload-logo.png"
                      alt="Upload Logo"
                    />
                  </div>
                </router-link>
              </div>
              <div class="mt-8">
                <p>{{ company.name }}</p>
              </div>
            </div>
          </div>
          <div class="lg:pl-8 lg:w-10/12">
            <div>
              <div
                class="
                  mb-16
                  border border-gray-border border-t-0 border-r-0 border-l-0
                "
              >
                <div class="flex justify-between">
                  <div
                    v-on:click="setActive('about')"
                    class="cursor-pointer flex-grow text-center"
                    :class="tabCss('about')"
                  >
                    <p>General</p>
                  </div>
                  <div
                    v-on:click="onSalaryEntriesClick()"
                    class="flex-grow text-center cursor-pointer"
                    :class="tabCss('salary-entries')"
                  >
                    <p>Salary Entires</p>
                  </div>
                </div>
              </div>
              <transition name="fade">
                <div
                  class="tab-pane"
                  :class="{ 'active show': isActive('about') }"
                >
                  <div>
                    <h1 class="text-2xl font-bold">About</h1>
                    <div class="mt-8" v-html="company.details"></div>
                    <div class="mt-8">
                      <div class="flex items-center">
                        <div class="mr-16">
                          <div>
                            <p class="text-sm lg:text-base">
                              {{ company.website }}
                            </p>
                            <small class="font-light">Website</small>
                          </div>
                        </div>
                        <div class="mr-16">
                          <div>
                            <p class="text-sm lg:text-base">
                              {{ company.estdAt }}
                            </p>
                            <small class="font-light">Founded</small>
                          </div>
                        </div>
                        <div>
                          <div>
                            <p class="text-sm lg:text-base">
                              {{ company.employees }}
                            </p>
                            <small class="font-light">Est. Employees</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-8">
                    <h1 class="text-2xl font-bold">Headquarters</h1>
                    <div class="mt-8">
                      <p class="mb-4">{{ company.address }}</p>
                      <Map v-if="!hideMap" :location="companyLocation" />
                    </div>
                  </div>
                  <div class="mt-4">
                    <small
                      >Something off or missing? Submit your entry
                      <router-link
                        class="text-primary-purple"
                        :to="{
                          name: 'AddCompanyEntry',
                          params: { id: company.id },
                        }"
                      >
                        here.</router-link
                      ></small
                    >
                  </div>
                </div>
              </transition>
              <transition name="fade">
                <div
                  class="tab-pane"
                  :class="{ 'active show': isActive('salary-entries') }"
                >
                  <div v-if="salaryLoaded" class="fade">
                    <div v-if="salaries.data">
                      <div
                        class="
                          grid grid-cols-2
                          md:grid-cols-3
                          lg:grid-cols-4
                          xl:grid-cols-4
                          mt-12
                          gap-6
                          recent-salary-submission-grid
                        "
                      >
                        <div v-for="salary in salaries.data" :key="salary.id">
                          <div
                            class="
                              rounded-md
                              border border-transparent
                              bg-white
                              shadow
                              recent-salary-submission-card
                            "
                          >
                            <header class="py-4 px-4 font-bold">
                              <span class="text-2xl text-purple"
                                >₦
                                {{ formatSalary(salary.totalYearlyComp) }}</span
                              >
                            </header>
                            <hr />
                            <div class="py-4 px-4">
                              <div>
                                <small
                                  class="text-xs text-black-light font-light"
                                  >Job Title</small
                                >
                                <p>{{ salary.jobTitle }}</p>
                              </div>
                              <div v-if="salary.jobLocation">
                                <small class="text-xs text-black-light"
                                  >Location</small
                                >
                                <p>{{ salary.jobLocation }}</p>
                              </div>
                              <div>
                                <small
                                  class="text-xs text-black-light font-light"
                                  >Posted</small
                                >
                                <p>{{ getTimeDifference(salary.createdAt) }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <h2>
                        No salaries added for this company. Add
                        <router-link
                          class="text-purple underline"
                          :to="{
                            name: 'Add Salary',
                            query: { companyName: company.name },
                          }"
                          >now.</router-link
                        >
                      </h2>
                    </div>
                  </div>
                  <div v-else>
                    <loading />
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import gmapsInit from "@/utils/gmap";
import { mapGetters, mapActions } from "vuex";
import { FILTERS } from "@/helpers/filters.js";
import Map from "../components/derived/Map.vue";
import Loading from "../components/core/Loading.vue";

export default {
  name: "CompanySingle",
  components: { Loading, Map },
  computed: {
    ...mapGetters(["companies", "salaries", "latLng"]),
    company: function () {
      return this.companies.data[0];
    },
  },
  data() {
    return {
      activeItem: "about",
      companyDataLoaded: false,
      salaryLoaded: false,
      companyId: null,
      hideMap: false,
      companyLocation: {},
    };
  },
  methods: {
    ...mapActions(["searchCompany", "fetchSalaries", "fetchLatLng"]),

    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      return (this.activeItem = menuItem);
    },
    tabCss(menuItem) {
      if (this.isActive(menuItem))
        return "border-2 border-purple border-r-0 border-l-0 border-t-0";
    },
    async getSalariesOfCompany() {
      await this.fetchSalaries(this.companyId);
      this.salaryLoaded = true;
    },
    onSalaryEntriesClick() {
      this.setActive("salary-entries");
      this.getSalariesOfCompany();
    },
    formatSalary(value) {
      return FILTERS.formatTotalSalary(value);
    },
    getTimeDifference(value) {
      return FILTERS.getTimeDifference(value);
    },
    async setupGoogleMap() {
      const google = await gmapsInit();

      console.log(google.maps);

      const geoCoder = new google.maps.Geocoder();

      geoCoder.geocode({ address: this.company.address }, (results, status) => {
        if (status == "OK") {
          console.log(results[0].geometry.location);
          this.companyLocation = results[0].geometry.location;
        } else {
          this.hideMap = true;
        }
      });
    },
  },
  async created() {
    let companyName = this.$route.params.companyName;

    let searchQueryObj = {
      name: "name",
      value: companyName,
    };

    await this.searchCompany(searchQueryObj);

    this.companyId = this.company.id;

    await this.setupGoogleMap();

    this.companyDataLoaded = true;
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.tab-pane {
  display: none;
}

.show {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.recent-salary-submission-grid {
  > div {
    min-height: 243px;
    max-width: 178px;
  }
}

hr {
  @apply border;
  @apply border-purple;
  @apply border-b-0;
  @apply border-r-0;
  @apply border-dashed;
  @apply border-l-0;
}
</style>